import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import api from "../services/api";
import { Link, useParams } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { BsFilterLeft } from "react-icons/bs";
import Modal from "../components/Cards/Modal";
import NewProductCard from "../components/Cards/NewProductCard";
import { formatPrice } from "../components/FormatPrice";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/Features/cartSlice";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { Skeleton } from "antd";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandInput, setBrandInput] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [mobileFilter, setMobileFilter] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { search } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [adding, setAdding] = useState(false);

  const [details, setDetails] = useState({
    size: "",
    quantity: 1,
    color: "",
  });

  const handleDetails = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const dispatch = useDispatch();

  const finalPrice =
    selectedProduct?.discount_price > 0
      ? selectedProduct?.discount_price
      : selectedProduct?.selling_price;

  let sizes = [];
  if (selectedProduct && selectedProduct.product_size) {
    try {
      sizes = JSON.parse(selectedProduct.product_size);
    } catch (error) {
      console.error("Error parsing product sizes:", error);
    }
  }
  let colors = [];
  if (selectedProduct && selectedProduct?.product_color) {
    try {
      colors = JSON.parse(selectedProduct?.product_color);
    } catch (error) {
      console.error("Error parsing product sizes:", error);
    }
  }

  const handleView = (productId) => {
    const product = products.find((p) => p.id === productId);
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const handleMinValueChange = (event) => {
    let newValue = parseInt(event.target.value);
    if (newValue >= maxValue) {
      newValue = maxValue - 1;
    }
    setMinValue(newValue);
  };

  const handleMaxValueChange = (event) => {
    let newValue = parseInt(event.target.value);
    if (newValue <= minValue) {
      newValue = minValue + 1;
    }
    setMaxValue(newValue);
  };

  const handleRangeChange = (event) => {
    setMinValue(parseInt(event.target.value));
  };

  useEffect(() => {
    setIsFetching(true);
    api.customer.category
      .getCategories()
      .then((res) => {
        setCategories(res.data?.categories);
      })
      .catch((res) => console.log(res));
    api.customer.subcategory
      .getSubcategories()
      .then((res) => {
        setSubcategories(res.data?.subcategories);
      })
      .catch((res) => console.log(res));
    api.customer.brand
      .getBrands()
      .then((res) => {
        setBrands(res.data?.brands);
      })
      .catch((res) => console.log(res));
    api.customer.products
      .getFilteredProducts(search)
      .then((res) => {
        const products = res.data?.products.filter(
          (product) => product.status !== "inactive"
        );

        const reviews = res.data?.reviews || [];
        
        const highestSellingPrice = products.reduce((maxPrice, product) => {
          const price = parseFloat(product.selling_price); // Convert to a number for comparison
          return price > maxPrice ? price : maxPrice;
        }, 0);
        setMaxValue(highestSellingPrice);
        const updatedProducts = products.map((product) => {
          // Get all reviews for the current product
          const productReviews = reviews.filter(
            (review) => review.product_id === product.id
          );

          // Extract the ratings from the reviews
          const ratings = productReviews.map((review) =>
            parseInt(review.rating, 10)
          );

          const averageRating =
            ratings.length > 0
              ? parseFloat(
                  (
                    ratings.reduce((sum, rating) => sum + rating, 0) /
                    ratings.length
                  ).toFixed(1)
                )
              : null;

          return {
            ...product,
            average_rating: averageRating,
          };
        });
        setProducts(updatedProducts);
      })
      .catch((res) => console.log(res))
      .finally(() => setIsFetching(false));
  }, [search]);

  // pagination
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const pageCount = Math.ceil(products.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = products.slice(startIndex, endIndex);

  const handleSearchInputChange = (e) => {
    setBrandInput(e.target.value);
  };

  const filteredBrands = brands.filter((brand) => {
    return brand.brand_name.toLowerCase().includes(brandInput.toLowerCase());
  });

  const filteredSubcategories = subcategories.filter((sub) => {
    return sub.category_id === selectedCategory;
  });

  const [filters, setFilters] = useState({
    category: "",
    subcategory: "",
    brand: "",
    size: "",
    minPrice: "",
    maxPrice: "",
    color: "",
    search: search || "",
  });

  const applyFilters = () => {
    const { category, subcategory, brand, size, minPrice, maxPrice, color } =
      filters;
    const filterOptions = {};
    if (category) {
      filterOptions.category_id = category;
    }
    if (subcategory) {
      filterOptions.subcategory_id = subcategory;
    }
    if (brand) {
      filterOptions.brand_id = brand;
    }
    if (size) {
      filterOptions.size = size;
    }
    if (minPrice) {
      filterOptions.price_min = minPrice;
    }
    if (maxPrice) {
      filterOptions.price_max = maxPrice;
    }
    if (color) {
      filterOptions.color = color;
    }
    if (search) {
      filterOptions.search = search;
    }

    api.customer.products
      .getFilteredProducts({ ...filterOptions })
      .then((res) => {
        const products = res.data?.products.filter(
          (product) => product.status !== "inactive"
        );
        setFilters((prev)=>({
          ...prev,
          search:''
        }))
        if (mobileFilter) {
          setMobileFilter(false);
        }
        const highestSellingPrice = products.reduce((maxPrice, product) => {
          const price = parseFloat(product.selling_price); // Convert to a number for comparison
          return price > maxPrice ? price : maxPrice;
        }, 0);
        setMaxValue(highestSellingPrice);
        setProducts(products);
      })
      .catch((res) => console.log(res))
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    if (search !== "") {
      applyFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleChangeFilter = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const addingToCart = () => {
    setAdding(true);
    const { product_name, id, vendor_id } = selectedProduct;

    if (
      sizes.length > 0 &&
      colors.length > 0 &&
      (details.color === "" || details.size === "")
    ) {
      toast.warn("Kindly add a color or a size");
      setAdding(false);
      return;
    }
    const formData = new FormData();
    formData.append("product_name", product_name);
    formData.append("quantity", details.quantity);
    formData.append("color", details.color);
    formData.append("size", details.size);
    formData.append("vendor", vendor_id);

    // Dispatch the thunk action
    dispatch(addToCart({ id, formData }))
      .unwrap()
      .then((res) => {
        toast.success(`${product_name} added to cart`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Failed to add ${product_name} to cart`);
      })
      .finally(() => setAdding(false));
  };

  return (
    <main className="flex flex-row gap-10 px-4 mb-8 py-4 w-full relative">
      {/* filters */}
      <aside className="hidden lg:flex flex-col w-[20%] bg-white py-8 ">
        {/* category */}
        <article className="filterdiv">
          <h3 className="filterheader">CATEGORY</h3>
          <ul className="filterUl filterLi h-[230px]">
            {categories.map(({ id, category_name }) => (
              <li key={id} className="mb-2">
                <input
                  type="checkbox"
                  name={category_name}
                  id={category_name}
                  checked={filters.category === id}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    handleChangeFilter("category", isChecked ? id : "");
                    setSelectedCategory(isChecked ? id : "");
                  }}
                  className="mr-2"
                />
                <label htmlFor={category_name} className="cursor-pointer">
                  {category_name}
                </label>
              </li>
            ))}
          </ul>
        </article>
        {/* SubCategories */}
        {selectedCategory && (
          <article className="filterdiv">
            <h3 className="filterheader">SUBCATEGORY</h3>
            <ul className="filterUl filterLi h-[230px]">
              {filteredSubcategories.map(({ id, subcategory_name }, idx) => (
                <li key={id} className="mb-2">
                  <input
                    type="checkbox"
                    name={subcategory_name}
                    id={subcategory_name}
                    checked={filters.subcategory === id}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      handleChangeFilter("subcategory", isChecked ? id : "");
                    }}
                    className="mr-2"
                  />
                  <label htmlFor={subcategory_name} className="cursor-pointer">
                    {subcategory_name}
                  </label>
                </li>
              ))}
            </ul>
          </article>
        )}

        {/* Price */}
        <article className="filterdiv">
          <h3 className="filterheader">PRICE (₦)</h3>
          <div className="h-[100px]">
            <input
              type="range"
              value={minValue}
              onChange={handleRangeChange}
              min={0}
              max={maxValue - 1}
              className="w-full"
            />
            <div className="flex gap-2 my-3 w-full items-center">
              <input
                type="number"
                value={minValue}
                onChange={handleMinValueChange}
                min={0}
                max={maxValue - 1}
                className="w-full border-black border-2 py-2 px-1 rounded-md"
              />
              <h3>-</h3>
              <input
                type="number"
                value={maxValue}
                onChange={handleMaxValueChange}
                min={minValue + 1}
                max={100}
                className="w-full border-black border-2 py-2 px-1 rounded-md"
              />
            </div>
          </div>
        </article>
        {/* Discount Percentage */}
        {/* <article className="filterdiv">
     <h3 className="filterheader">DISCOUNT PERCENTAGE</h3>
     <ul className="filterUl filterLi h-[150px]">
      <li>
       <input type="radio" name="" id="" />
       <label htmlFor="">50% or more</label>
      </li>
      <li>
       <input type="radio" name="" id="" />
       <label htmlFor="">40% or more</label>
      </li>
      <li>
       <input type="radio" name="" id="" />
       <label htmlFor="">30% or more</label>
      </li>
      <li>
       <input type="radio" name="" id="" />
       <label htmlFor="">20% or more</label>
      </li>
     </ul>
    </article> */}
        {/* Brand */}
        <article className="filterdiv">
          <h3 className="filterheader">BRAND</h3>
          <input
            type="search"
            name=""
            id=""
            placeholder="Search"
            value={brandInput}
            onChange={handleSearchInputChange}
            className="outline-none border-black border-2 w-full py-1 px-2 mb-2 rounded-lg"
          />
          <ul className="filterUl filterLi h-[230px]">
            {filteredBrands.map((brand) => {
              return (
                <li key={brand.brand_slug}>
                  <input
                    type="checkbox"
                    name={brand.brand_name}
                    id={brand.brand_name}
                    checked={filters.brand === brand.id}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      handleChangeFilter("brand", isChecked ? brand.id : "");
                    }}
                  />
                  <label htmlFor={brand.brand_name}>{brand.brand_name}</label>
                </li>
              );
            })}
          </ul>
        </article>
        {/* Size */}
        <article className="filterdiv">
          <h3 className="filterheader">SIZE</h3>
          <ul className="filterUl filterLi h-[150px]">
            <li>
              <input type="checkbox" name="" id="" />
              <label htmlFor="">S</label>
            </li>
            <li>
              <input type="checkbox" name="" id="" />
              <label htmlFor="">M</label>
            </li>
            <li>
              <input type="checkbox" name="" id="" />
              <label htmlFor="">L</label>
            </li>
            <li>
              <input type="checkbox" name="" id="" />
              <label htmlFor="">XL</label>
            </li>
          </ul>
        </article>
        <article>
          <button type="button" className="submitBtn" onClick={applyFilters}>
            Apply Filter
          </button>
        </article>
      </aside>
      {/* products */}
      <section className="w-full lg:w-[80%] bg-white ">
        {/* top div containing filter for mobile */}
        <div className="flex items-center justify-between mb-2 border-slate-200 border-b-2 pb-3 px-6">
          <div>
            <div className="flex items-center overflow-hidden gap-2 border-[2px] border-gray-400 text-lg my-4 rounded-lg">
              <button type="button" className="bg-gray-200 font-semibold p-2">
                All listings
              </button>
              <Link to="/auctions" className="pr-2">
                Bidding
              </Link>
            </div>
            {/* <h3 className="text-lg font-bold">Shop Online in Nigeria</h3> */}
            <p className="text-slate-300 text-base">
              {products.length} products found
            </p>
          </div>
          <button
            type="button"
            className="lg:hidden"
            onClick={() => setMobileFilter(!mobileFilter)}
          >
            <BsFilterLeft size={25} />
          </button>
        </div>
        {/* bottom div containing products */}
        {isFetching ? (
          <div className="flex flex-wrap gap-4 justify-center mt-6">
            {Array(3)
              .fill()
              .map((_, index) => (
                <article
                  key={index}
                  className="w-full relative md:w-[350px] border p-4 rounded-lg shadow-lg overflow-hidden"
                >
                  {/* Image Skeleton */}
                  <Skeleton variant="square" height={200} />

                  {/* Details Skeleton */}
                  <div className="mt-4">
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="50%" />
                  </div>

                  {/* Price Skeleton */}
                  <div className="mt-4 flex items-center justify-between">
                    <div className="flex items-center gap-5 justify-between">
                      <Skeleton variant="text" width={60} />
                    </div>
                    <Skeleton variant="rectangular" width={100} height={36} />
                  </div>
                </article>
              ))}
          </div>
        ) : (
          <section>
            <div className="flex flex-wrap gap-4 justify-center">
              {paginatedData.length > 0 ? (
                paginatedData.map((product) => {
                  return (
                    <NewProductCard
                      onView={handleView}
                      key={product.id}
                      price={product.selling_price}
                      product_name={product.product_name}
                      rating={product?.average_rating}
                      image={
                        "https://api.ebn.ng/public" + product.product_thumbnail
                      }
                      id={product.id}
                      tag={product.productTag}
                      discount={product.discount_price}
                      createdBy={product.created_by}
                      slug={product.product_slug}
                      {...product}
                    />
                  );
                })
              ) : (
                <div className="flex justify-center items-center text-xl">
                  <h1>No products found</h1>
                </div>
              )}
            </div>
            {products.length > itemsPerPage && (
              <div className="my-8">
                <ReactPaginate
                  previousLabel={"⇽"}
                  nextLabel={"⇾"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName="paginateContainer"
                  pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
                  previousClassName="nextPrev"
                  nextClassName="nextPrev"
                  activeClassName="bg-green-500 text-white"
                />
              </div>
            )}
          </section>
        )}
      </section>
      {mobileFilter && (
        <div className="absolute bg-black bg-opacity-50 w-full z-50">
          <aside className=" flex flex-col w-[60%] bg-white py-8 ">
            <article className="m-2 ml-auto text-2xl cursor-pointer">
              <IoClose onClick={() => setMobileFilter(false)} />
            </article>
            {/* category */}
            <article className="filterdiv">
              <h3 className="filterheader">CATEGORY</h3>
              <ul className="filterUl filterLi h-[230px]">
                {categories.map(({ id, category_name }) => (
                  <li key={id} className="mb-2">
                    <input
                      type="checkbox"
                      name={category_name}
                      id={category_name}
                      checked={filters.category === id}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleChangeFilter("category", isChecked ? id : "");
                        setSelectedCategory(isChecked ? id : "");
                      }}
                      className="mr-2"
                    />
                    <label htmlFor={category_name} className="cursor-pointer">
                      {category_name}
                    </label>
                  </li>
                ))}
              </ul>
            </article>
            {/* SubCategories */}
            {selectedCategory && (
              <article className="filterdiv">
                <h3 className="filterheader">SUBCATEGORY</h3>
                <ul className="filterUl filterLi h-[230px]">
                  {filteredSubcategories.map(
                    ({ id, subcategory_name }, idx) => (
                      <li key={id} className="mb-2">
                        <input
                          type="checkbox"
                          name={subcategory_name}
                          id={subcategory_name}
                          checked={filters.subcategory === id}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            handleChangeFilter(
                              "subcategory",
                              isChecked ? id : ""
                            );
                          }}
                          className="mr-2"
                        />
                        <label
                          htmlFor={subcategory_name}
                          className="cursor-pointer"
                        >
                          {subcategory_name}
                        </label>
                      </li>
                    )
                  )}
                </ul>
              </article>
            )}

            {/* Price */}
            <article className="filterdiv">
              <h3 className="filterheader">PRICE (₦)</h3>
              <div className="h-[100px]">
                <input
                  type="range"
                  value={minValue}
                  onChange={handleRangeChange}
                  min={0}
                  max={maxValue - 1}
                  className="w-full"
                />
                <div className="flex gap-2 my-3 w-full items-center">
                  <input
                    type="number"
                    value={minValue}
                    onChange={handleMinValueChange}
                    min={0}
                    max={maxValue - 1}
                    className="w-full border-black border-2 py-2 px-1 rounded-md"
                  />
                  <h3>-</h3>
                  <input
                    type="number"
                    value={maxValue}
                    onChange={handleMaxValueChange}
                    min={minValue + 1}
                    max={100}
                    className="w-full border-black border-2 py-2 px-1 rounded-md"
                  />
                </div>
              </div>
            </article>
            {/* Brand */}
            <article className="filterdiv">
              <h3 className="filterheader">BRAND</h3>
              <input
                type="search"
                name=""
                id=""
                placeholder="Search"
                value={brandInput}
                onChange={handleSearchInputChange}
                className="outline-none border-black border-2 w-full py-1 px-2 mb-2 rounded-lg"
              />
              <ul className="filterUl filterLi h-[230px]">
                {filteredBrands.map((brand) => {
                  return (
                    <li key={brand.brand_slug}>
                      <input
                        type="checkbox"
                        name={brand.brand_name}
                        id={brand.brand_name}
                        checked={filters.brand === brand.id}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleChangeFilter(
                            "brand",
                            isChecked ? brand.id : ""
                          );
                        }}
                      />
                      <label htmlFor={brand.brand_name}>
                        {brand.brand_name}
                      </label>
                    </li>
                  );
                })}
              </ul>
            </article>
            {/* Size */}
            <article className="filterdiv">
              <h3 className="filterheader">SIZE</h3>
              <ul className="filterUl filterLi h-[150px]">
                <li>
                  <input type="checkbox" name="" id="" />
                  <label htmlFor="">S</label>
                </li>
                <li>
                  <input type="checkbox" name="" id="" />
                  <label htmlFor="">M</label>
                </li>
                <li>
                  <input type="checkbox" name="" id="" />
                  <label htmlFor="">L</label>
                </li>
                <li>
                  <input type="checkbox" name="" id="" />
                  <label htmlFor="">XL</label>
                </li>
              </ul>
            </article>
            <article>
              <button
                type="button"
                className="submitBtn"
                onClick={applyFilters}
              >
                Apply Filter
              </button>
            </article>
          </aside>
        </div>
      )}
      {/* modal */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {selectedProduct && (
          <section className="flex flex-col lg:flex-row gap-8 mt-[50px] w-[80%] mx-auto">
            {/* Product slider */}
            <div className="w-full lg:w-[40%]">
              <img
                src={"https://api.ebn.ng/public" + selectedProduct.product_thumbnail}
                alt={"just"}
                className="w-full  object-contain"
              />
            </div>
            {/* Product Details */}
            <aside className="w-full lg:w-[55%]">
              <h3 className="text-xl lg:text-2xl w-full">
                {selectedProduct?.product_name}
              </h3>

              <div className="flex flex-col gap-4 my-6">
                <article className="flex gap-3 items-center justify-between">
                  <p>Size :</p>
                  <select
                    name="size"
                    id="size"
                    value={details.size}
                    className="w-[80%]"
                    onChange={handleDetails}
                  >
                    <option value="">Select a size</option>
                    {sizes.map((size) => {
                      return (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      );
                    })}
                  </select>
                </article>
                <article className="flex gap-3  items-center justify-between">
                  <p>Color :</p>
                  <select
                    name="color"
                    id="color"
                    value={details.color}
                    onChange={handleDetails}
                    className="w-[80%]"
                  >
                    <option value="">Select a color</option>
                    {colors.map((color) => {
                      return (
                        <option key={color} value={color}>
                          {color}
                        </option>
                      );
                    })}
                  </select>
                </article>
              </div>

              {/* the ratings and reviews with price section */}
              <div className="mt-[20px]">
                {/* details */}
                <div className="flex gap-3 items-center">
                  <h3 className="text-xl lg:text-2xl my-2 font-bold">
                    ₦{formatPrice(finalPrice)}
                  </h3>
                  {selectedProduct.discount_price > 0 && (
                    <h3 className="text-xl lg:text-2xl my-2 font-bold text-red-900">
                      ₦
                      <strike>
                        {formatPrice(selectedProduct.selling_price)}
                      </strike>
                    </h3>
                  )}
                </div>
                {/* extra details */}
                <div>
                  {/* buttons redux */}
                  <div className="mt-[20px]">
                    <div className="mt-4">
                      <button
                        type="button"
                        disabled={adding === true}
                        onClick={addingToCart}
                        className={`py-3 px-5 bg-green-700 text-white font-medium rounded-md ${
                          adding || selectedProduct?.product_qty < 1
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                      >
                        {adding ? (
                          <CircularProgress
                            size={20}
                            sx={{ color: "whitesmoke" }}
                          />
                        ) : selectedProduct?.product_qty < 1 ? (
                          "Out of Stock"
                        ) : (
                          "Add to Cart"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </section>
        )}
      </Modal>
    </main>
  );
};

export default Products;
