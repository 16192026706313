import React from "react";
import { formatPrice } from "./FormatPrice";
import { useSelector } from "react-redux";

const Shipping = ({ totalPrice }) => {
  const { couponData } = useSelector((store) => store.cart);
  return (
    <section className="shipping">
      <div className="shippingItem pt-3">
        <h3>Payable Amount</h3>
        <span>₦{formatPrice(totalPrice)}</span>
      </div>
      {couponData && (
        <div>
          <div className="shippingItem pt-3">
            <h3>Total</h3>
            <span>₦{formatPrice(couponData.subtotal)}</span>
          </div>
          <div className="shippingItem pt-3">
            <h3>Discount Percentage</h3>
            <span>{couponData.couponDiscount}%</span>
          </div>
        </div>
      )}
    </section>
  );
};

export default Shipping;
