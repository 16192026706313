import React, { useEffect, useState } from "react";
import cartImage from "../assets/cart.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CartItem from "../components/CartItem";
import Shipping from "../components/Shipping";
import { fetchCart, getCouponCal } from "../redux/Features/cartSlice";
import Skeleton from "@mui/material/Skeleton";
import AddCoupon from "../components/AddCoupon";
import { toast } from "react-toastify";
import api from "../services/api";

const Cart = () => {
  const { cartItems, cartQty, cartTotal, status, couponData } = useSelector(
    (store) => store.cart
  );
  const { user } = useSelector((store) => store.auth);
  const [coupon, setCoupon] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch fetchCart when the component mounts
    dispatch(fetchCart());
  }, [dispatch]);

  const couponChange = (e) => {
    setCoupon(e.target.value);
  };

  const handleCoupon = () => {
    if (!user) {
      toast.warn("You must be logged in");
      return;
    }
    if (coupon === "") {
      toast.info("Coupon is required");
      return;
    }
    const formData = new FormData();
    formData.append("coupon_name", coupon);
    api.customer.checkout
      .addCoupon(formData)
      .then((res) => {
        toast.info(res.data?.error);
        dispatch(getCouponCal())
      })
      .catch((res) => console.log(res));
  };

  const removeAppliedCoupon = () => {
    api.customer.checkout.removeCoupon().then(res=>console.log(res)).catch(err=>console.log(err))
  }

  return (
    <main>
      {status === "loading" ? (
        // Show Skeleton when loading cart data
        <section className="mx-5">
          <section className="my-4">
            <Skeleton variant="text" width={300} height={45} />
            <Skeleton variant="text" width={150} height={25} />
          </section>
          <section>
            {/* Table Head */}
            <article className="carttablehead">
              <div>
                <Skeleton variant="rectangular" width={150} height={40} />
              </div>
              <div>
                <Skeleton variant="rectangular" width={100} height={40} />
              </div>
              <div>
                <Skeleton variant="rectangular" width={100} height={40} />
              </div>
              <div>
                <Skeleton variant="rectangular" width={100} height={40} />
              </div>
            </article>
            {/* Skeleton cart Items */}
            <div>
              {[1, 2, 3].map((item) => (
                <Skeleton
                  key={item}
                  variant="rectangular"
                  height={80}
                  className="my-3"
                />
              ))}
            </div>
          </section>
        </section>
      ) : cartItems.length === 0 ? (
        <section className="flex flex-col items-center justify-center px-[30px] gap-4 mb-10">
          <img src={cartImage} alt="cart" className="h-3/4" />
          <div className="text-center flex flex-col gap-4">
            <h3 className="text-3xl font-extrabold">
              Your Cart Is Currently Empty!
            </h3>
            <div className="flex flex-col gap-1 text-gray-600 text-sm md:text-base lg:text-xl font-medium">
              <p>
                Before proceeding to checkout you must add some products to your
                shopping cart.
              </p>
              <p>
                You will find a lot of interesting products on our "Shop" page.
              </p>
            </div>
            <button
              type="button"
              onClick={() => navigate("/products")}
              className="bg-blue-700 return"
            >
              Return To Shop
            </button>
          </div>
        </section>
      ) : (
        <section className="mx-5">
          <section className="my-4">
            <h3 className="text-[45px] text-gray-800 font-bold">Your Cart</h3>
            <p className="text-base">
              There are <span className="text-green-500">{cartQty}</span>{" "}
              products in your cart
            </p>
          </section>
          <section>
            {/* Table Head */}
            <article className="carttablehead">
              <div className="">
                <h3 className="cartheader">Products</h3>
              </div>
              <div>
                <h3 className="cartheader">Price</h3>
              </div>
              <div className="">
                <p className="cartheader">Quantity</p>
              </div>
              <div>
                <h3 className="cartheader">SubTotal</h3>
              </div>
            </article>
            {/* Cart Items */}
            <div>
              {cartItems?.map((cartItem) => {
                return (
                  <CartItem
                    id={cartItem.id}
                    rowId={cartItem.rowId}
                    key={cartItem.rowId}
                    title={cartItem.name}
                    qty={cartItem.qty}
                    price={cartItem.price}
                    thumbnail={cartItem.options?.image}
                    subTotal={cartItem.subtotal}
                  />
                );
              })}
            </div>

            <button
              type="submit"
              onClick={() => navigate("/products")}
              className="returntoshop"
            >
              Return To Shop
            </button>
          </section>

          <div className="my-3">
            <AddCoupon
              couponCode={coupon}
              handleChange={couponChange}
              applyCoupon={handleCoupon}
            />
            {
              couponData !== null && <p onClick={removeAppliedCoupon}>Remove <span>{couponData.couponName}</span> coupon</p>
            }
          </div>
          {/* Checkout section */}
          <section className="proceedtocheckout">
            <div className="cartcheckout">
              <h3 className="font-bold text-xl mb-3">Cart Total</h3>
              <Shipping totalPrice={cartTotal} />
              <div>
                {!user ? (
                  <div>
                    <p className="my-8 font-medium">
                      You can't proceed to checkout without signing in or
                      logging in.
                    </p>
                    <div className="flex gap-10 text-lg text-red-800">
                      <Link
                        to="/login"
                        className="border-2 py-2 px-6 rounded-md"
                      >
                        Login
                      </Link>
                      <Link
                        to="/register"
                        className="border-2 py-2 px-6 rounded-md"
                      >
                        SignUp
                      </Link>
                    </div>
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="checkoutbutton"
                    onClick={() => navigate("/checkout")}
                  >
                    Proceed to checkout
                  </button>
                )}
              </div>
            </div>
          </section>
        </section>
      )}
    </main>
  );
};

export default Cart;
