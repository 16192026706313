import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import BidderCard from "../components/Cards/BidderCard";
import { toast } from "react-toastify";
import api from "../services/api";
import { formatPrice } from "../components/FormatPrice";
import { CiCircleInfo } from "react-icons/ci";
import { CircularProgress } from "@mui/material";

const SingleAuction = () => {
  const { id } = useParams();
  const [auction, setAuction] = useState("");
  const [currentBid, setCurrentBid] = useState("");
  const [currentBidders, setCurrentBidders] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const predefinedBids = [100, 500, 1000, 5000, 10000];
  const [selectedBid, setSelectedBid] = useState(null);
  const [lastBidTime, setLastBidTime] = useState(null);

  useEffect(() => {
    const startDate = new Date(auction.start_time);
    const endDate = new Date(auction.end_time);
    const updateCountdown = () => {
      const now = new Date();

      if (now < startDate) {
        // Bidding has not started yet
        const timeRemaining = startDate.getTime() - now.getTime();
        setTimeRemaining(timeRemaining);
        setIsButtonDisabled(true);
      } else if (now >= endDate) {
        // Bidding has ended
        setTimeRemaining(0);
        setIsButtonDisabled(true);
      } else {
        // Bidding is ongoing
        const timeRemaining = endDate.getTime() - now.getTime();
        setTimeRemaining(timeRemaining);
        setIsButtonDisabled(false);
      }
    };

    updateCountdown(); // Initial call
    const intervalId = setInterval(updateCountdown, 1000); // Update every second

    return () => {
      clearInterval(intervalId); // Cleanup the interval on unmount
    };
  }, [auction.start_time, auction.end_time]);

  useEffect(() => {
    setIsFetching(true);
    api.customer.bidding
      .getSingleAuction(id)
      .then((res) => {
        setCurrentBid(res.data.auction.auction_price);
        setAuction(res.data?.auction);
      })
      .catch((res) => console.log(res))
      .finally(() => setIsFetching(false));
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch current bid data from the server
        const res = await api.customer.bidding.getCurrentBid(id);
        setCurrentBid(
          res.data.bidding[0]?.bidding_price ||
          res.data.auction[0].auction_price
        );
        setCurrentBidders(res.data.bidding);
      } catch (error) {
        console.log(error);
      }
    };

    // Fetch data initially
    fetchData();

    // Set up interval to fetch data every 2 seconds
    const intervalId = setInterval(fetchData, 4000);

    // Clean up interval on component unmount or when dependencies change
    return () => clearInterval(intervalId);
  }, [id]); // Depend only on 'id'

  const formatTime = (time) => {
    if (time < 10) {
      return `0${time}`;
    }
    return time;
  };

  const getRemainingTime = () => {
    const weeks = Math.floor(timeRemaining / (1000 * 60 * 60 * 24 * 7));
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return (
      <div>
        <ul className="flex flex-wrap gap-2 bg-red-300 text-white text-xl font-bold px-2 py-1 rounded-md shadow-xl my-1">
          <li>
            <h1>Weeks</h1>
            <span>{formatTime(weeks)}</span>
          </li>
          <li>
            <h1>Days</h1>
            <span>{formatTime(days)}</span>
          </li>
          <li>
            <h1>hours</h1>
            <span>{formatTime(hours)}</span>
          </li>
          <li>
            <h1>Minutes</h1>
            <span>{formatTime(minutes)}</span>
          </li>
          <li>
            <h1>Seconds</h1>
            <span>{formatTime(seconds)}</span>
          </li>
        </ul>
      </div>
    );
  };

  const getRemainingTimeToBid = () => {
    if (!lastBidTime) return "0:00";
    const now = new Date();
    const remainingSeconds = Math.max(120 - Math.floor((now - lastBidTime) / 1000), 0);
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const canBidAgain = () => {
    if (!lastBidTime) return true;
    const now = new Date();
    const diff = (now - lastBidTime) / 1000 / 60; // Difference in minutes
    return diff >= 2;
  };

  const handleBid = (value) => {
    if (!canBidAgain()) {
      toast.error(`You must wait ${getRemainingTimeToBid()} mins before bidding again.`);
      return;
    }

    setSelectedBid(value);
    setIsButtonDisabled(true);
    const newBid = currentBid + value;
    if (newBid <= currentBid) {
      toast.error(`${newBid} is less than current bid`);
      setIsButtonDisabled(false);
      return;
    }

    const formData = new FormData();
    formData.append("bidding_price", newBid);
    formData.append("auction_id", id);

    api.customer.bidding
      .makeABid(formData)
      .then((res) => {
        setCurrentBid(newBid);
        setSelectedBid(null);
        setIsButtonDisabled(false);
        setLastBidTime(new Date());
        toast.success(`${newBid} submitted`);
      })
      .catch((res) => toast.error(res.data?.message));
  };


  return (
    <section className="m-4 md:mx-[3%] flex flex-col gap-10">
      {isFetching ? (
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="mt-[2%]">
          <p className="text-center text-xl lg:text-2xl">
            Submit your bid for{" "}
            <span className="font-semibold">{auction?.product_name}</span>
          </p>
          {/* auction details */}
          <section className="flex flex-col lg:flex-row gap-8 mt-[20px]">
            <div className="w-full lg:w-[45%]">
              <img
                src={
                  "https://api.ebn.ng/public/" +
                  auction.thumbnail
                }
                alt=""
                className="w-full"
              />
            </div>
            <aside className="w-full lg:w-[50%]">
              <h3 className="text-2xl lg:text-4xl w-full md:w-[85%]">
                {auction?.product_name}
              </h3>
              <h3 className="text-xl lg:text-2xl my-2 font-medium text-green-700">
                Current bid: <span>₦{formatPrice(currentBid)}</span>
              </h3>
              <ul className="my-[10px] flex flex-col gap-4">
                <li className="flex gap-2 items-center">
                  <p className="font-bold text-lg">
                    {!isButtonDisabled ? "Time Left" : "Bid starts in"}:{" "}
                    {getRemainingTime()}
                  </p>
                </li>
                <li className="flex gap-2 items-center">
                  <p className="font-bold text-lg">
                    {new Date(auction.end_time) < Date.now()
                      ? "Ended On"
                      : "Ending On"}
                    : {new Date(auction.end_time).toLocaleString()}
                  </p>
                </li>
                <li className="flex flex-col gap-2">
                  <p className="font-bold text-lg">Item Description:</p>
                  <p>{auction?.description}</p>
                </li>
              </ul>
              <div className="mt-[30px]">
                <article className="flex gap-2 items-center my-1">
                  <CiCircleInfo />
                  <p>Bid using the buttons below</p>
                </article>
                <div id="" className="flex gap-3 mb-8">
                  <article className="flex gap-4">
                    {predefinedBids.map((value) => (
                      <button
                        key={value}
                        type="button"
                        className={`px-4 py-2 border-2 rounded-lg ${selectedBid === value ? 'bg-green-600 text-white' : 'bg-gray-300'}`}
                        onClick={() => handleBid(value)}
                        disabled={isButtonDisabled}
                      >
                        {isButtonDisabled && selectedBid === value ? (
                          <span className="flex items-center">
                            <CircularProgress />
                          </span>
                        ) : (
                          `+₦${formatPrice(value)}`
                        )}
                      </button>
                    ))}
                  </article>
                  {/* <button
                    type="button"
                    className={
                      isButtonDisabled
                        ? "disabled"
                        : `bg-green-600 px-6 py-2 text-white rounded-lg`
                    }
                    onClick={handleBid}
                    disabled={isButtonDisabled}
                  >
                    Place bid
                  </button> */}
                </div>
                <button
                  type="button"
                  className={
                    isButtonDisabled
                      ? "disabled"
                      : `bg-blue-600 px-6 py-2 text-white rounded-lg`
                  }
                >
                  Add to watchlist
                </button>
              </div>
            </aside>
          </section>
          {/* other details */}
          <section>
            <div>
              <h1 className="text-2xl font-bold">
                {auction?.status === "close" ? "Bidders" : "Active bidders"}
              </h1>
              <article className="my-[30px]">
                <Swiper
                  slidesPerView={3}
                  spaceBetween={50}
                  // centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    240: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    640: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 5,
                      spaceBetween: 50,
                    },
                    1024: {
                      slidesPerView: 6,
                      spaceBetween: 50,
                    },
                    1280: {
                      slidesPerView: 7,
                      spaceBetween: 50,
                    },
                  }}
                  navigation={false}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper p-4 my-auto"
                >
                  {currentBidders.slice(0, 6).map((x) => {
                    return (
                      <SwiperSlide key={x.id}>
                        <BidderCard bidder={x.user_id} bid={x.bidding_price} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </article>
            </div>
            <div></div>
          </section>
          {/* winner */}
          {auction?.status === "close" && auction.winner_id !== null && (
            <section>
              <div>
                <h1 className="text-2xl font-bold">Bid Winner</h1>
                <p>Congratulations to user {auction.winner_id}</p>
              </div>
              <div></div>
            </section>
          )}
        </div>
      )}
    </section>
  );
};

export default SingleAuction;
