import axios from "axios";
import service from "../index";
import routes from "../routes";
import { auth } from "./auth";
import { toast } from "react-toastify";

// Request Interceptor
axios.interceptors.request.use(
  async (config) => {
    const token = service.getTokensLocalStorage("customer-tokens");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token?.access}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // first error
    if (error.response.status === 401) {
      //  window.location.replace("/register");
      const token = service.getTokensLocalStorage("customer-tokens");

      auth
        .refreshToken(token)
        .then((res) => {
          service.setTokensLocalStorage({ access: res.data.access_token });
        })
        .catch((res) => console.log("refreshToken", res));
    } else if (error.response.status === 422) {
      toast.error(error.response.data.error);
      toast.error(error.response.data.message);
    } else if (error.response.status === 500) {
      toast.error(error.response.data.message);
    } else {
      toast.error(error.response.data.message);
    }
    // second error, error 422, email already used
    // if (error.response.status === 422) {
    //   toast.error(error.response.data?.error)
    // } else {
    //   console.log("error", error);
    // }
    return error;
  }
);

const api = {
  contactUs: async (postBody) => {
    return await axios.post(routes["contact-us"](), postBody);
  },
  customer: {
    profile: {
      get: async () => {
        return await axios.get(routes.customer.profile["get-details"]());
      },
      updateProfile: async (postBody) => {
        return await axios.post(
          routes.customer.profile["update-profile"](),
          postBody
        );
      },
      getTransactions: async () => {
        return await axios.get(routes.customer.profile["get-transactions"]());
      },
    },
    products: {
      getProducts: async () => {
        return await axios.get(routes.customer.products["get-allProducts"]());
      },
      getFilteredProducts: async (queryParams) => {
        return await axios.get(
          routes.customer.products["get-allFilteredProducts"](),
          { params: { ...queryParams } }
        );
      },
      getProduct: async (id) => {
        return await axios.get(routes.customer.products["get-Product"](id));
      },
      getAllApprovedProducts: async () => {
        return await axios.get(
          routes.customer.products["get-allApprovedProducts"]()
        );
      },
      productsByCategory: async (id) => {
        return await axios.get(
          routes.customer.products["product-by-category"](id)
        );
      },
    },
    vendor: {
      getVendors: async () => {
        return await axios.get(routes.customer.vendors["get-allVendors"]());
      },
      getVendorProducts: async (id) => {
        return await axios.get(
          routes.customer.vendors["get-Vendor-products"](id)
        );
      },
      getVendor: async (id) => {
        return await axios.get(routes.customer.vendors["get-Vendor"](id));
      },
      getActiveVendors: async () => {
        return await axios.get(
          routes.customer.vendors["get-allActiveVendors"]()
        );
      },
      getInactiveVendors: async () => {
        return await axios.get(
          routes.customer.vendors["get-allInactiveVendors"]()
        );
      },
      vendorProductCount: async (id) => {
        return await axios.get(
          routes.customer.vendors["get-vendorProductCount"](id)
        );
      },
    },
    brand: {
      getBrands: async () => {
        return await axios.get(routes.customer.brand["get-brands"]());
      },
      getBrand: async (id) => {
        return await axios.get(routes.customer.brand["get-brand"](id));
      },
    },
    category: {
      getCategories: async () => {
        return await axios.get(routes.customer.category["get-categories"]());
      },
      getCategory: async (id) => {
        return await axios.get(routes.customer.category["get-category"](id));
      },
    },
    subcategory: {
      getSubcategories: async () => {
        return await axios.get(
          routes.customer.subcategory["get-subcategories"]()
        );
      },
      getCategory: async (id) => {
        return await axios.get(
          routes.customer.subcategory["get-subcategory"](id)
        );
      },
    },
    cart: {
      addToCart: async (id, postBody) => {
        return await axios.post(
          routes.customer.cart["add-to-cart"](id),
          postBody,
          {
            withCredentials: true,
          }
        );
      },
      getCart: async () => {
        return await axios.get(routes.customer.cart["get-cart"](), {
          withCredentials: true,
        });
      },
      getCount: async () => {
        return await axios.get(routes.customer.cart["get-count"](), {
          withCredentials: true,
        });
      },
      deleteCartItem: async (id) => {
        return await axios.post(
          routes.customer.cart["delete-cart-item"](id),
          {},
          {
            withCredentials: true,
          }
        );
      },
      cartIncrement: async (id) => {
        return await axios.post(
          routes.customer.cart["cart-increment"](id),
          {},
          {
            withCredentials: true,
          }
        );
      },
      cartDecrement: async (id) => {
        return await axios.post(
          routes.customer.cart["cart-decrement"](id),
          {},
          {
            withCredentials: true,
          }
        );
      },
    },
    wishlist: {
      addToWishlist: async (postBody, id) => {
        return await axios.post(
          routes.customer.wishlist["add-to-wishlist"](id),
          postBody
        );
      },
      getWishlist: async () => {
        return await axios.get(routes.customer.wishlist["get-wishlist"]());
      },
      deleteWishlistItem: async (id) => {
        return await axios.delete(
          routes.customer.wishlist["delete-wishlist-item"](id)
        );
      },
    },
    watchlist: {
      addToWatchlist: async (id, postBody) => {
        return await axios.post(
          routes.customer.watchlist["add-to-watchlist"](id),
          postBody
        );
      },
      getWatchlist: async () => {
        return await axios.get(routes.customer.watchlist["get-watchlist"]());
      },
      deleteWatchlistItem: async (id) => {
        return await axios.delete(
          routes.customer.watchlist["delete-watchlist-item"](id)
        );
      },
    },
    resetPassword: async (postBody) => {
      return await axios.post(routes.customer["reset-password"](), postBody);
    },
    activateResetPassword: async (postBody) => {
      return await axios.post(
        routes.customer["activate-resetPassword"](),
        postBody
      );
    },
    getVendors: async () => {
      return await axios.get(routes.customer["get-vendors"]());
    },
    raiseClaim: async (postBody) => {
      return await axios.post(routes.customer.claim["raise-claim"](), postBody);
    },
    getClaim: async () => {
      return await axios.get(routes.customer.claim["view-claim"]());
    },
    checkout: {
      checkout: async (postBody) => {
        return await axios.post(routes.customer.checkout.checkout(), postBody, {
          withCredentials: true,
        });
      },
      addCoupon: async (postBody) => {
        return await axios.post(
          routes.customer.checkout["add-coupon"](),
          postBody,
          {
            withCredentials: true,
          }
        );
      },
      calcCoupon: async () => {
        return await axios.get(routes.customer.checkout["get-coupon-calc"](), {
          withCredentials: true,
        });
      },
      removeCoupon: async () => {
        return await axios.get(routes.customer.checkout["remove-coupon"](), {
          withCredentials: true,
        });
      },
      fetchDeliveryInfo: async (id) => {
        return await axios.post(
          routes.customer.checkout["fetch-delivery-info"](id),
          {
            withCredentials: true,
          }
        );
      },
      initializePayment: async (id) => {
        return await axios.post(
          routes.customer.checkout["initialize-payment"](id),
          {
            withCredentials: true,
          }
        );
      },
      confirmPayment: async (id, postBody) => {
        return await axios.post(
          routes.customer.checkout["confirm-payment"](id),
          postBody,
          {
            withCredentials: true,
          }
        );
      },
    },
    bidding: {
      makeABid: async (postBody) => {
        return await axios.post(
          routes.customer.bidding["make-bid"](),
          postBody
        );
      },
      getAllAuctions: async () => {
        return await axios.get(routes.customer.bidding["get-all-auctions"]());
      },
      getAllBiddings: async () => {
        return await axios.get(routes.customer.bidding["get-all-biddings"]());
      },
      getSingleAuction: async (id) => {
        return await axios.get(routes.customer.bidding["get-auction"](id));
      },
      getCurrentBid: async (id) => {
        return await axios.get(routes.customer.bidding["get-current-bid"](id));
      },
      getWinnings: async () => {
        return await axios.get(
          routes.customer.bidding["get-auction-winnings"]()
        );
      },
      initializeAuctionPayment: async (id) => {
        return await axios.post(
          routes.customer.bidding["initialize-auction-payment"](id)
        );
      },
      confirmAuctionPayment: async (id, postBody) => {
        return await axios.post(
          routes.customer.bidding["confirm-auction-payment"](id),
          postBody
        );
      },
      addAuctionToCart: async (id) => {
        return await axios.post(
          routes.customer.bidding["add-auction-to-cart"](id),
          {},
          {
            withCredentials: true,
          }
        );
      },
      bidsCheckout: async (postBody) => {
        return await axios.post(
          routes.customer.bidding["bids-checkout"](),
          postBody,
          {
            withCredentials: true,
          }
        );
      },
    },
    review: {
      postReview: async (postBody) => {
        return await axios.post(
          routes.customer.review["make-a-review"](),
          postBody
        );
      },
      getReviews: async (id) => {
        return await axios.get(
          routes.customer.review["get-review-for-product"](id)
        );
      },
    },
  },
};

export default api;
