import React from "react";
import { useSelector } from "react-redux";

const AddCoupon = ({ couponCode, handleChange, applyCoupon }) => {
  const { couponData } = useSelector((store) => store.cart);
 return (
  <div className="flex items-center gap-4">
   <input
    type="text"
    name=""
    id=""
    placeholder="Enter Coupon"
    className="border-2 outline-none px-2 py-3"
    value={couponCode}
    onChange={handleChange}
   />
   <button type="submit" className={couponData === null ? "couponbutton" : 'disabled'} onClick={applyCoupon} disabled={couponData !== null}>
    Apply Coupon
   </button>
  </div>
 );
};

export default AddCoupon;
