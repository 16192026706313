import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import api from "../services/api";
import { Link, useParams } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { BsFilterLeft } from "react-icons/bs";
import Modal from "../components/Cards/Modal";
import NewProductCard from "../components/Cards/NewProductCard";
import { formatPrice } from "../components/FormatPrice";
import Breadcrumb from "../components/BreadCrumb";
import { FaHome, FaFolderOpen } from "react-icons/fa";
import Loading from "../components/Loading";
import { addToCart, fetchCart } from "../redux/Features/cartSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";

const ProductsByCategory = () => {
  const [adding, setAdding] = useState(false);
  const { categoryId, categoryName } = useParams();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [mobileFilter, setMobileFilter] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const dispatch = useDispatch();

  const finalPrice =
    selectedProduct?.discount_price > 0
      ? selectedProduct?.discount_price
      : selectedProduct?.selling_price;

  let sizes = [];
  if (selectedProduct && selectedProduct.product_size) {
    try {
      sizes = JSON.parse(selectedProduct.product_size);
    } catch (error) {
      console.error("Error parsing product sizes:", error);
    }
  }
  let colors = [];
  if (selectedProduct && selectedProduct?.product_color) {
    try {
      colors = JSON.parse(selectedProduct?.product_color);
    } catch (error) {
      console.error("Error parsing product sizes:", error);
    }
  }

  const handleView = (productId) => {
    const product = products.find((p) => p.id === productId);
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    const fetchCategoriesWithProducts = async () => {
      try {
        // Fetch the list of categories
        const res = await api.customer.category.getCategories();
        const categories = res.data?.categories;

        // Fetch products for each category using their ID
        const categoriesWithProducts = await Promise.all(
          categories.map(async (category) => {
            try {
              const categoryProducts =
                await api.customer.products.productsByCategory(category.id);
              return {
                ...category,
                productsCount: categoryProducts.data?.["products-counts"],
              };
            } catch (err) {
              console.error(
                `Error fetching products for category ${category.id}`,
                err
              );
              return { ...category, productsCount: 0 };
            }
          })
        );
        setCategories(categoriesWithProducts);
      } catch (err) {
        console.error("Error fetching categories", err);
      }
    };

    fetchCategoriesWithProducts();
  }, []);

  // pagination
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const pageCount = Math.ceil(products.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = products.slice(startIndex, endIndex);

  const [filters, setFilters] = useState({
    category: "",
    subcategory: "",
    brand: "",
    size: "",
  });

  const handleChangeFilter = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  useEffect(() => {
    setIsFetching(true);
    api.customer.products
      .productsByCategory(categoryId)
      .then((res) => {
        setProducts(res.data?.product);
      })
      .catch((res) => console.log(res))
      .finally(() => setIsFetching(false));
  }, [categoryId]);

  const breadcrumbItems = [
    { label: "Home", href: "/", icon: <FaHome className="w-4 h-4" /> },
    {
      label: "Products",
      href: "/products",
      icon: <FaFolderOpen className="w-4 h-4" />,
    },
    { label: `${categoryName}` },
  ];
  const [details, setDetails] = useState({
    size: "",
    quantity: 1,
    color: "",
  });
  const handleDetails = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const addingToCart = () => {
    setAdding(true);

    const { id } = selectedProduct;

    const formData = new FormData();
    formData.append("product_name", selectedProduct.product_name);
    formData.append("quantity", 1);
    formData.append("color", details.color);
    formData.append("size", details.size);
    formData.append("vendor", selectedProduct.vendor_id);

    // Dispatch the thunk action
    dispatch(addToCart({ id, formData }))
      .unwrap()
      .then((res) => {
        toast.success(`${selectedProduct.product_name} added to cart`);
        dispatch(fetchCart());
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Failed to add ${selectedProduct.product_name} to cart`);
      })
      .finally(() => setAdding(false));
  };
  return (
    <main className="flex flex-row gap-10 px-4 mb-8 py-4 w-full h-screen bg-gray-50 relative">
      {/* filters */}
      <aside className="hidden lg:flex flex-col w-[20%] bg-white py-8 ">
        {/* category */}
        <article className="filterdiv">
          <h3 className="filterheader">CATEGORY</h3>
          <ul className="filterUl filterLi h-[90%] overflow-y-scroll">
            {categories.map((cat, idx) => {
              const categoryImage = cat.category_image
                ? `https://api.ebn.ng/public${cat.category_image}`
                : "path/to/placeholder/image.png"; // Placeholder for missing images

              return (
                <Link
                  to={`/products/categories/${cat.category_name}/${cat.id}`}
                  key={cat.id}
                  className="group"
                >
                  <div className="flex justify-between gap-2 items-center px-2 h-[40px] border border-gray-100 hover:bg-gray-200 transition">
                    <img
                      src={categoryImage}
                      alt={cat.category_name || "Category Image"}
                      className="w-[25px] h-[25px] rounded-full transition-transform group-hover:scale-110 object-contain"
                    />
                    <p className="truncate">{cat.category_name}</p>
                    <small>{cat.productsCount}</small>
                  </div>
                </Link>
              );
            })}
          </ul>
        </article>
      </aside>
      {/* products */}
      <section className="w-full lg:w-[80%] bg-white ">
        <Breadcrumb items={breadcrumbItems} />
        {/* top div containing filter for mobile */}
        <div className="flex items-center justify-between mb-2 border-slate-200 border-b-2 pb-3 px-6">
          <button
            type="button"
            className="lg:hidden"
            onClick={() => setMobileFilter(!mobileFilter)}
          >
            <BsFilterLeft size={25} />
          </button>
        </div>
        {/* bottom div containing products */}
        <section>
          {isFetching ? (
            <div className="flex justify-center items-center h-screen">
              <Loading />
            </div>
          ) : (
            <div className="allVendors">
              {paginatedData.length > 0 ? (
                paginatedData.map((product) => {
                  return (
                    <NewProductCard
                      onView={handleView}
                      key={product.id}
                      price={product.selling_price}
                      product_name={product.product_name}
                      rating={product.productRating}
                      image={
                        "https://api.ebn.ng/public" + product.product_thumbnail
                      }
                      id={product.id}
                      tag={product.productTag}
                      discount={product.discount_price}
                      createdBy={product.created_by}
                      slug={product.product_slug}
                    />
                  );
                })
              ) : (
                <div className="flex justify-center items-center text-xl">
                  <h1>No products found</h1>
                </div>
              )}
            </div>
          )}
          {paginatedData.length > itemsPerPage && (
            <div className="my-8">
              <ReactPaginate
                previousLabel={"⇽"}
                nextLabel={"⇾"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="paginateContainer"
                pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
                previousClassName="nextPrev"
                nextClassName="nextPrev"
                activeClassName="bg-green-500 text-white"
              />
            </div>
          )}
        </section>
      </section>
      {mobileFilter && (
        <div className="absolute bg-black bg-opacity-50 w-full z-50">
          <aside className=" flex flex-col w-[60%] bg-white py-8 ">
            <article className="m-2 ml-auto text-2xl cursor-pointer">
              <IoClose onClick={() => setMobileFilter(false)} />
            </article>
            {/* category */}
            <article className="filterdiv">
              <h3 className="filterheader">CATEGORY</h3>
              <ul className="filterUl filterLi h-[230px]">
                {categories.map((cat, idx) => {
                  return (
                    <li key={cat.id}>
                      <input
                        type="checkbox"
                        name={cat.category_name}
                        id={cat.category_name}
                        checked={filters.category === cat.id}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleChangeFilter(
                            "category",
                            isChecked ? cat.id : ""
                          );
                        }}
                      />
                      <label htmlFor={cat.category_name}>
                        {cat.category_name}
                      </label>
                    </li>
                  );
                })}
              </ul>
            </article>
          </aside>
        </div>
      )}
      {/* modal */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {selectedProduct && (
          <section className="flex flex-col lg:flex-row gap-8 mt-[50px] w-[80%] mx-auto">
            {/* Product slider */}
            <div className="w-full lg:w-[40%]">
              <img
                src={
                  "https://api.ebn.ng/public" +
                  selectedProduct.product_thumbnail
                }
                alt={"just"}
                className="w-full  object-contain"
              />
            </div>
            {/* Product Details */}
            <aside className="w-full lg:w-[55%]">
              <h3 className="text-xl lg:text-2xl w-full">
                {selectedProduct?.product_name}
              </h3>

              <div className="flex flex-col gap-4 my-6">
                <article className="flex gap-3 items-center justify-between">
                  <p>Size :</p>
                  <select
                    name="size"
                    id="size"
                    value={details.size}
                    className="w-[80%]"
                    onChange={handleDetails}
                  >
                    <option value="">Select a size</option>
                    {sizes.map((size) => {
                      return (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      );
                    })}
                  </select>
                </article>
                <article className="flex gap-3  items-center justify-between">
                  <p>Color :</p>
                  <select
                    name="color"
                    id="color"
                    value={details.color}
                    onChange={handleDetails}
                    className="w-[80%]"
                  >
                    <option value="">Select a color</option>
                    {colors.map((color) => {
                      return (
                        <option key={color} value={color}>
                          {color}
                        </option>
                      );
                    })}
                  </select>
                </article>
              </div>

              {/* the ratings and reviews with price section */}
              <div className="mt-[20px]">
                {/* details */}
                <div className="flex gap-3 items-center">
                  <h3 className="text-xl lg:text-2xl my-2 font-bold">
                    ₦{formatPrice(finalPrice)}
                  </h3>
                  {selectedProduct.discount_price > 0 && (
                    <h3 className="text-xl lg:text-2xl my-2 font-bold text-red-900">
                      ₦
                      <strike>
                        {formatPrice(selectedProduct.selling_price)}
                      </strike>
                    </h3>
                  )}
                </div>
                {/* extra details */}
                <div>
                  {/* buttons redux */}
                  <div className="mt-[20px]">
                    <div className="mt-4">
                      <button
                        type="button"
                        disabled={adding === true}
                        onClick={addingToCart}
                        className={`py-3 px-5 bg-green-700 text-white font-medium rounded-md ${
                          adding || selectedProduct?.product_qty < 1
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                      >
                        {adding ? (
                          <CircularProgress
                            size={20}
                            sx={{ color: "whitesmoke" }}
                          />
                        ) : selectedProduct?.product_qty < 1 ? (
                          "Out of Stock"
                        ) : (
                          "Add to Cart"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </section>
        )}
      </Modal>
    </main>
  );
};

export default ProductsByCategory;
