import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import catImage from "../../assets/empty_category_image.jpg";

const FeaturedCard = ({ featureImage, category, categoryId, featureStock }) => {
  const navigate = useNavigate();
  return (
    <Con
      onClick={() => navigate(`/products/categories/${category}/${categoryId}`)}
      className="w-[120px] lg:w-[180px]"
    >
      <img
        src={"https://api.ebn.ng/public" + featureImage}
        alt="Product"
        onError={(e) => (e.target.src = catImage)}
      />
      <div className="text">
        <p className="category">{category}</p>
        <small>{featureStock} items</small>
      </div>
    </Con>
  );
};

export default FeaturedCard;

const Con = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px 10px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
  text-align: center;
  flex-shrink: 0;
  cursor: pointer;

  &:hover {
    transition: all 0.3s linear;
    border: 1px solid #049b04;

    .text {
      p,
      small {
        color: #049b04;
      }
    }
  }

  p {
    font-size: 13px;
    font-weight: 600;
    color: #000;
  }

  small {
    color: rgba(123, 120, 120, 0.36);
    font-weight: 600;
    font-size: 15px;
  }

  img {
    width: 50px;
    height: 50px;
  }
`;
